body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none; }

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.ac_link {
  cursor: pointer; }
  .ac_link:hover {
    text-decoration: underline; }

.ac_button {
  border-radius: 3px;
  color: #fff;
  background: #00c4b5;
  padding: 8px 24px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border: none; }
  .ac_button:hover {
    background: #00a99c;
    cursor: pointer; }
  .ac_button:disabled, .ac_button.disabled, .ac_button[disabled='disabled'] {
    background: #acc4bf;
    opacity: 0.2;
    cursor: default; }

.dropdown {
  position: relative;
  display: inline-block; }

.drop_ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 100%;
  right: 0px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }

.drop_li, .drop_a {
  color: #000;
  text-decoration: none; }

.drop_li {
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;
  text-align: center; }

.drop_li:last-child {
  border-bottom: none; }

.drop_li:hover {
  background-color: #e5e5e5;
  color: white; }

.drop_button {
  border-radius: 3px;
  color: #fff;
  background: #00c4b5;
  padding: 8px 24px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 0;
  font-size: 14px;
  border: none; }
  .drop_button:hover {
    background: #00a99c;
    cursor: pointer; }
  .drop_button:disabled, .drop_button.disabled, .drop_button[disabled='disabled'] {
    background: #acc4bf;
    opacity: 0.2;
    cursor: default; }

.drop_button:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 8px solid;
  border-color: white transparent transparent transparent;
  right: 30px;
  top: 30px; }

.text_title {
  font-weight: 700;
  font-size: 18px;
  color: #3a3f42; }

.text_title_small {
  font-weight: 700;
  font-size: 16px;
  color: #3a3f42; }

.text_heading_light {
  font-weight: 700;
  font-size: 14px;
  color: #b6b9bb; }

.text_heading {
  font-weight: 700;
  font-size: 14px;
  color: #3a3f42; }

.text_body {
  font-weight: 600;
  font-size: 14px;
  color: #3a3f42; }

.text_body_link, .text_body a {
  font-weight: 500;
  font-size: 14px;
  color: #00c4b5; }

.text_body_big {
  font-weight: 500;
  font-size: 16px;
  color: #3a3f42; }

.text_body_big_link, .text_body_big a {
  font-weight: 500;
  font-size: 16px;
  color: #00c4b5; }

.text_body_placeholder {
  font-weight: 600;
  font-size: 14px;
  color: #b6b9bb; }

.text_body_light {
  font-weight: 600;
  font-size: 14px;
  color: #707274; }

.text_caption {
  font-weight: 600;
  font-size: 12px;
  color: #707274; }

.text_caption_dark {
  font-weight: 700;
  font-size: 12px;
  color: #3a3f42; }

.text_caption_light {
  font-weight: 600;
  font-size: 12px;
  color: #b6b9bb; }

.text_caption_small {
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  color: #b6b9bb; }

.text_link, .text_link_big {
  font-weight: 700;
  font-size: 12px;
  color: #00c4b5;
  cursor: pointer; }
  .text_link:hover, .text_link_big:hover {
    text-decoration: underline; }

.text_link_big {
  font-weight: 600;
  font-size: 14px; }

.text_error {
  font-weight: 600;
  font-size: 12px;
  color: #ef5d60; }

.text_success {
  font-weight: 600;
  font-size: 12px;
  color: #00bfa9; }

.header-content {
  max-width: 1156px;
  margin: auto;
  height: 80px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (max-width: 720px) {
    .header-content {
      padding: 0 16px; } }

.network-title {
  font-weight: 700;
  font-size: 24px; }
  @media screen and (max-width: 720px) {
    .network-title {
      font-size: 14px; } }

.title-caption {
  font-size: 16px;
  line-height: 1.5em; }
  @media screen and (max-width: 720px) {
    .title-caption {
      font-size: 12px; } }

.header-logo {
  max-height: 64px;
  margin-right: 24px; }
  @media screen and (max-width: 720px) {
    .header-logo {
      margin-right: 8px; } }

.banner-text-container {
  min-height: 240px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 1.5;
  background-color: #3a3f42; }
  @media screen and (max-width: 720px) {
    .banner-text-container {
      font-size: 16px;
      min-height: 200px; } }

